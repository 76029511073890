import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ShadowBox from 'components/ShadowBox';
import Enrollments from './Enrollments';
import { formatDate } from 'utils';

const useStyles = makeStyles({
  label: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#636363",
  }
});

function Label(props) {
  const classes = useStyles();
  return <span className={classes.label}>{props.children}&nbsp;</span>;
}

function StudentInfo({ student }) {
  return (
    <ShadowBox display="flex" alignItems="stretch" gap={2} mt={0}>
      <Avatar
        src={student.profileUrl}
        sx={{ width: 96, height: 96, border: '1px solid lightgrey' }}
      >
        {student?.name?.charAt(0)}
      </Avatar>
      <Box flexGrow={2}>
        <Typography variant="h6">{student?.name}</Typography>
        <Typography variant="subtitle2">
          <Label>Roll No:</Label>{student?._id}
        </Typography>
        <Typography variant="subtitle2">
          <Label>Email:</Label>{student?.email}
        </Typography>
        <Typography variant="subtitle2">
          <Label>Last Active:</Label>
          {student?.metadata?.lastActive ?
            formatDate(student?.metadata?.lastActive, 'DD-MMM-YYYY, hh:mma') :
            "NA"}
        </Typography>
      </Box>
      <Box flexGrow={1} maxHeight={200} overflow="hidden">
        <Enrollments />
      </Box>
    </ShadowBox>
  );
}

export default StudentInfo;