import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import StaticTable from "components/Tables/StaticTable";
import { useNavigate, useParams } from "react-router-dom";
import { exportTemplateAnalyticsExport, getUsersAnalytics, getUsersPerformanceAnalytics } from "services";
import { getAverageScore } from "utils";

const commonProperties = {
  type: "number",
  renderCell: ({ row, field }) =>
    row.finishedAttempts === 0 ? "-" : Math.round(row[field]) || 0,
  align: "center",
};

const columns = [
  { field: "name", label: "Name", type: "string", minWidth: 120, flex: 1, fixed: 1 },
  {
    field: "overallScore",
    type: "number",
    ...commonProperties,
    label: "Overall Score",
    minWidth: 80,
    sort: true,
  },
  {
    field: "contentOverall",
    label: "Content",
    ...commonProperties,
    minWidth: 80,
  },
  {
    field: "communicationOverall",
    label: "Speech",
    ...commonProperties,
    minWidth: 80,
  },
  {
    field: "finishedAttempts",
    label: "Finished Attempts",
    ...commonProperties,
    minWidth: 120,
  },
  {
    field: "unfinishedAttempts",
    label: "Unfinished Attempts",
    renderCell: ({ row, field }) => Math.round(row[field]) || "-",
    align: "center",
    type: "number",
    minWidth: 120,
  },
  {
    field: "communicationConfidence",
    label: "Confidence",
    ...commonProperties,
    minWidth: 100,
  },
  {
    field: "communicationDelivery",
    label: "Delivery",
    ...commonProperties,
    minWidth: 70,
  },
  {
    field: "communicationFluency",
    label: "Fluency",
    ...commonProperties,
    minWidth: 70,
  },
  {
    field: "communicationWord_Choice",
    label: "Word choice",
    ...commonProperties,
    minWidth: 100,
  },
  {
    field: "contentSimilarity",
    label: "Similarity",
    ...commonProperties,
    minWidth: 80,
  },
  {
    field: "contentRelevance",
    label: "Relevance",
    ...commonProperties,
    minWidth: 90,
  },
  {
    field: "contentLogic_Flow",
    label: "Logic flow",
    ...commonProperties,
    minWidth: 100,
  },
  {
    field: "timeTaken",
    label: "Time Taken By Candidate",
    ...commonProperties,
    minWidth: 100,
  },
  {
    field: "allottedTime",
    label: "Allotted Time",
    ...commonProperties,
    minWidth: 100,
  },
];

function UsersAnalytics({ batch, template , forTemplateAnalytics, forPersonalizedTemplate=false }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [exportRows, setExportRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const initialFilters = [
    "name", "overallScore", "contentOverall", "communicationOverall", "finishedAttempts",
    "unfinishedAttempts"
  ];

  React.useEffect(() => {
    if (batch && id) {
      setLoading(true);
      getUsersAnalytics(batch, forPersonalizedTemplate ? { personalizedTemplateDraftId : id} : {templateId: id} )
        .then((userAnalytics) => setRows(userAnalytics))
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [id, batch]);

  React.useEffect(() => {
    if (batch && id) {
      setLoading(true);
      getUsersPerformanceAnalytics(batch, { templateId: id })
        .then((userAnalytics) => setExportRows(userAnalytics))
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [id, batch]);

  const _rows = React.useMemo(() => {
    const allRows = rows?.map((rowData) => {
      const overallScore = getAverageScore(rowData.contentOverall + rowData.communicationOverall);

      return {
        ...rowData,
        overallScore: isNaN(overallScore) || overallScore === null ? "-" : overallScore
      };
    });

    return allRows;
  }, [rows]);

  const _exportRows = React.useMemo(() => {
    const allExportRows = exportRows?.map((rowData) => {
      const overallScore = getAverageScore(rowData.contentOverall + rowData.communicationOverall);

      return {
        ...rowData,
        overallScore: isNaN(overallScore) || overallScore === null ? "-" : overallScore
      };
    });

    return allExportRows;
  }, [exportRows]);

  const getActions = [
    {
      title: "View Performance",
      renderIcon: (param) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 22 22" fill="none">
          <rect y="14" width="6" height="8" rx="1" fill="#025093" />
          <rect x="8" y="8" width="6" height="14" rx="1" fill="#5856D6" />
          <rect x="16" width="6" height="22" rx="1" fill="#007AFF" />
        </svg>
      ),
      label: "View Performance",
      onClick: (param) => navigate(`/students/${param.id}`)
    }
  ];

  return (
    <>
      <Typography mt={2} mx={0} variant="h6">
        Users Performance
      </Typography>

      <StaticTable
        columns={columns}
        loading={loading}
        actions={getActions}
        rows={_rows}
        exportRows={_exportRows}
        initialFilters={initialFilters}
        noRowsMessage="No students found in this batch!!"
        sortByColumns={
          [
            { ...columns[1], sortByTitle: "Scores: High to Low", sortBy: 'desc' },
            { ...columns[1], sortByTitle: "Scores: Low to High", sortBy: 'asc' },
            { ...columns[4], sortByTitle: "Engagement: High to Low", sortBy: 'desc' },
            { ...columns[4], sortByTitle: "Engagement: Low to High", sortBy: 'asc' }
          ]
        }
        exportByAttempts={true}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        useItemProps={{
          name: template.name,
          exportService: exportTemplateAnalyticsExport,
          payload: template
        }}
        forTemplateAnalytics={forTemplateAnalytics}
      />
    </>
  );
}

export default UsersAnalytics;
