import Client from "./_client";
import { Session } from "utils";

const apiUrl = process.env.REACT_APP_API_URL;

export const getUsers = async (userFilters = {}) => {
    let url = "/user?";

    Object.keys(userFilters).forEach((key) => {
        if (userFilters[key]) url += `${key}=${userFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get users. Please try again."
        );
    }

    return result?.data;
};

export const getStudents = async (userFilters = {}) => {
    let url = "/user/students?";

    Object.keys(userFilters).forEach((key) => {
        if (userFilters[key] || userFilters[key] === false) url += `${key}=${userFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get students. Please try again."
        );
    }

    return result?.data;
};

export const getUserById = async (userId, query = {}) => {
    let url = `/user/${userId}?`;

    Object.keys(query).forEach((key) => {
        if (query[key]) url += `${key}=${query[key]}&`;
    });

    let result = await new Client({
        path: url,
    }).get();
    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to get user. Please try again."
        );
    }

    return result.data;
}

export const saveUser = async (id, user) => {
    let result;
    if (id) {
        result = await new Client({
            path: `/user/${id}`,
            payload: user,
        }).put();
    } else {
        result = await new Client({
            path: `/user`,
            payload: user,
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save user. Please try again."
        );
    }

    return result.data;
}

export const saveClient = async (id, client) => {
    let result;
    if (id) {
        result = await new Client({
            path: `/client/${id}`,
            payload: client,
        }).put();
    } else {
        result = await new Client({
            path: `/client`,
            payload: client,
        }).post();
    }

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to save client. Please try again."
        );
    }

    return result.data;
}

export const deleteUserById = async (userId) => {
    const result = await new Client({
        path: `/user/${userId}`
    }).delete();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to delete user. Please try again."
        );
    }

    return result.data;
}

export const sendMailToClient = async (id, data) => {

    const result = await new Client({
        path: `/user/${id}/send-mail-to-client`,
        payload: data
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to send email. Please try again."
        );
    }

    return result.data;
}

export const updateProfileStatus = async (userId, newStatus) => {

    let result = await new Client({
        path: `/user/${userId}/profile-status`,
        payload: {
            profileStatus: newStatus,
            userId
        },
    }).put();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to update profile status. Please try again."
        );
    }

    return result.data;
}

export const generateMagicLink = async (
    { id, email, userName, userEmail, sep, templateId }
) => {
    let url = `/user/${id}/generate-link`;
    if (sep) url += "/sep";

    const result = await new Client({
        path: url,
        payload: {
            email,
            templateId,
            user: {
                name: userName,
                email: userEmail,
            }
        },
    }).post();


    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message ||
            "Uh Oh! Unable to generate link!!"
        );
    }

    return result?.data;
}

export const getClients = async (clientFilters = {}) => {
    let url = "/client?";

    Object.keys(clientFilters).forEach((key) => {
        if (clientFilters[key]) url += `${key}=${clientFilters[key]}&`;
    });

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get clients. Please try again."
        );
    }

    return result?.data;
};

export const changePassword = async ({ currentPassword, newPassword }) => {
    let result = await new Client({
        path: `/user/${Session.userId}/reset-password`,
        payload: {
            currentPassword,
            newPassword
        },
    }).put();

    if (!(result.ok || result.code === 200)) {
        throw new Error(result.message);
    }

    return result?.data;
}

export const updateInprepLogo = async ({
    isNew, logo, ...imageMetadata
}) => {
    const formData = new FormData();

    Object.keys(imageMetadata).forEach((key) => {
        if (imageMetadata[key] !== undefined)
            formData.append(key, imageMetadata[key]);
    });

    if (isNew) formData.append("logo", logo);

    let result = await fetch(`${apiUrl}/client/interview/${Session.userId}/logo`, {
        headers: {
            userId: Session.userId,
            "languify-application": "client",
            "languify-access-token": Session.accessToken,
        },
        method: "PUT",
        body: formData,
    });

    if (!(result.ok || result.status === 200)) {
        throw new Error(result.message || "Uh Oh! Unable to save the changes.");
    }

    result = await result.json();

    return result?.data;
};

export const sendInvites = async (clientId, usersData = []) => {
    const result = await new Client({
        path: `/client/interview/${clientId}/invite`,
        payload: { usersData },
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw result;
    }

    return result?.data;
};

export const sendSEPInvitesForTemplate = async (templateId, usersData = []) => {
    const result = await new Client({
        path: `/client/sep/template/${templateId}/invite`,
        payload: { usersData },
    }).post();

    if (!(result.ok || result.code === 200)) {
        throw result;
    }

    return result?.data;
};

export const overwriteClientById = async (id, client) => {
    let url = `/client/${id}/overwrite`;

    const result = await new Client({
        path: url,
        payload: client
    }).put();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to update client. Please try again."
        );
    }

    return result?.data;
};

export const getPreviousMonthUsersCount = async () => {
    let url = `/user/${Session.userId}/previous-month-users`;

    const result = await new Client({
        path: url,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to fetch users count. Please try again."
        );
    }

    return result?.data;
};

export const getUserAssessmentAnalytics = async (userId) => {
    const result = await new Client({
        path: `/user/${userId}/assessment-analytics`
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to fetch analytics. Please try again!!"
        );
    }

    return result?.data;
};

export const getUserBalanceInfo = async(user) => {
  let url = `/credit/info?user=${user}`;

  let result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200))
      throw new Error(
          result.message || "Uh Oh! Unable to get balance. Please try again."
      );

  return result.data;
}

export const getUsageOverview = async(user, filters) => {
  let url = `/client/usage-overview?user=${user}&`;

  Object.keys(filters).forEach((key) => {
    if (filters[key]) url += `${key}=${filters[key]}&`;
});

  let result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200))
      throw new Error(
          result.message || "Uh Oh! Unable to get balance. Please try again."
      );

  return result.data;
}

export const getUserCredits = async(user, creditFilters) => {
  let url = `/credit?user=${user}&`;

  Object.keys(creditFilters).forEach((key) => {
    if (creditFilters[key] !== undefined) url += `${key}=${creditFilters[key]}&`;
  });

  let result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200))
      throw new Error(
          result.message || "Uh Oh! Unable to get balance. Please try again."
      );

  return result.data;
}

export const getUserTransactions = async(user, transactionFilters) => {
  let url = `/transaction?user=${user}&`;

  Object.keys(transactionFilters).forEach((key) => {
    if (transactionFilters[key] !== undefined) url += `${key}=${transactionFilters[key]}&`;
  });

  let result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200))
      throw new Error(
          result.message || "Uh Oh! Unable to get balance. Please try again."
      );

  return result.data;
}

