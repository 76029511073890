import React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CustomButton from "components/CustomButton";
import DynamicTable from "components/DynamicTable";
import ScreenHeading from "components/ScreenHeading";
import DuplicateTemplateDialog from "dialogs/DuplicateTemplate";
import TemplateDrafts from "lists/TemplateDrafts";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ArchiveIcon from '@mui/icons-material/Archive';
import DraftsIcon from '@mui/icons-material/Drafts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useEditorStore from "editors/TemplateEditor/editorStore";

import {
  archiveTemplateById,
  deleteTemplateById,
  getBatches,
  getDomains,
  getTemplates,
} from "services";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { TourSteps, useSnackbar, useTour } from "contexts";
import { Session, applyFiltersByHistory, copyToClipboard, formatDate, getClientPrefix } from "utils";
import { IconButton } from "@mui/material";
import { BootstrapTooltip } from "components/InfoIconWithTooltip";

const Status = {
  live: { color: "#00664A", label: "Live", bgColor: '#DCFCE7' },
  expired: { color: "#C51407", label: "Expired", bgColor: '#FFE6E3' },
  unpublished: { color: "#BF7900", label: "Unpublished", bgColor: '#FFF6E5' },
};

const sx = {
  chip: {
    borderRadius: 4,
    padding: '0px 18px',
    width: 'fit-content',
    height: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chipText: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.7,
  },
  active: {
    color: '#02569D'
  },
  inActive: {
    color: '#a6a6a6',
    '&:hover': {
      color: '#cccccc',
      cursor: 'pointer'
    }
  },
};

const filters = [
  { field: "domain", type: "select", label: "Domain" },
  { field: "batch", type: "select", label: "Batch" },
  { field: "type", type: "select", label: "Type" },
];

function StatusChip({ bgColor, color, label, tooltip }) {

  return (
    <Box sx={sx.chip} style={{ backgroundColor: bgColor || '#747775' }}>
      <BootstrapTooltip title={tooltip} >
        <Typography sx={sx.chipText} color={color || 'black'}>
          {label || 'Label'}
        </Typography>
      </BootstrapTooltip>
    </Box>
  );
}

function AssessmentTabs({ active = 0, handleActiveChange }) {
  const items = React.useMemo(() => [
    {
      id: 'active', label: 'Active', icon: <TaskAltIcon fontSize="small" />,
      tagLine: 'Active assessments will be accessible by students and their data will be continuously updated in reports.'
    },
    {
      id: 'archive', label: 'Archives', icon: <ArchiveIcon fontSize="small" />,
      tagLine: 'Archived assessment will not be visible to students, however their data will still appear in reports.'
    },
    {
      id: 'draft', label: 'Drafts', icon: <DraftsIcon fontSize="small" />,
      tagLine: "Draft assessments are not accessible by students and their data won't appear in reports until the assessment is published."
    },
  ], []);

  return (
    <Box pl={2}>
      <Box
        width={'100%'} height='fit-content' display='flex'
        style={{ backgroundColor: 'white' }} gap={8} pt={2} pb={1}
        alignItems='center' justifyContent='left'
      >
        {
          items.map((item, index) =>
            <Box>
              <Box display='flex' flexDirection='column' gap='4px'
                onClick={() => handleActiveChange(index)}
              >
                <Box
                  display='flex' gap={1} alignItems='center' height='fit-content'
                  sx={active === index ? sx.active : sx.inActive}
                >
                  {item.icon}
                  <Typography variant="body2" fontWeight={600}>
                    {item.label}
                  </Typography>
                </Box>
                <Box width='100%' border='1.5px solid' alignSelf='center'
                  borderRadius='1px' borderColor='primary.main'
                  visibility={(active === index ? 'visible' : 'hidden')}

                >
                </Box>
              </Box>
            </Box>
          )
        }
      </Box>
      <Typography mt='10px' fontSize='14px'>
        <span style={{ color: 'red' }}>*</span>
        {
          items[active].tagLine
        }
      </Typography>
    </Box>
  );
}

function Templates(props) {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const { state: tourState } = useTour();

  const [searchParams] = useSearchParams();
  const [activeAssessment, setActiveAssessment] = React.useState(0);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [filterOptions, setFilterOptions] = React.useState({
    type: [
      { id: ["practice"], label: "Practice" },
      { id: ["assignment"], label: "Assignment" },
      { id: ["follow_up"], label: "Follow up" },
    ],
  });
  const [duplicate, setDuplicate] = React.useState(false);
  const [refreshData, setRefreshData] = React.useState(false);
  const pageFilters = useEditorStore((state) => state.pageFilters)
  const setPageFilters = useEditorStore((state) => state.setPageFilters);
  const pageHistory = Session.pageHistory

  const [initialFilters, setInitialFilters] = React.useState({
    user: Session.userId,
    include: ["domain", "batch"],
    includeTotal: true,
    orderBy: "createdAt",
    order: "desc",
    archive: false,
    type: ["practice", "assignment", "follow_up"]
  });

  const columns = React.useMemo(() => ([
    { field: "name", label: "Name", minWidth: 180, maxWidth: 200 },
    {
      field: "status",
      label: "Status",
      align: "center",
      minWidth: 130,
      flex: 0,
      renderCell: ({ row }) => {
        let status = Status.unpublished;
        let tooltip = `Publising on ${formatDate(row.publishOn, 'DD/MM/YYYY')}`;

        if (row.publishOn && dayjs(row.publishOn).isBefore(new Date())) {
          status = Status.live;
          if(dayjs(row.expiresOn).year()===9999){
            tooltip = `Never Expires`;
          }else{
            tooltip = `Expires on ${formatDate(row.expiresOn, 'DD/MM/YYYY')}`;
          }
        }
        if (row.expiresOn && dayjs(row.expiresOn).isBefore(new Date())) {
          status = Status.expired;
          tooltip = `Expired on ${formatDate(row.expiresOn, 'DD/MM/YYYY')}`;
        }

        return (
          <Box width='100%' display='flex' justifyContent='center'>
            <StatusChip
              color={status.color} bgColor={status.bgColor} label={status.label} tooltip={tooltip}
            />
          </Box>
        );
      },
    },
    {
      id: "type",
      label: "Type",
      minWidth: 100,
      align: "center",
    },
    {
      id: "domain",
      label: "Domain",
      minWidth: 150,
      align: "center",
      renderCell: ({ row }) => row?.domain?.name || "",
    },
    {
      id: "batch",
      label: "Batch",
      minWidth: 100, maxWidth: 180,
      align: "center",
      renderCell: ({ row }) => row?.batch?.name || "-",
    },
    {
      id: "metadata.numberOfQuestions",
      label: "Questions",
      type: "number",
      renderCell: ({ row }) => (row.type === 'follow_up') ? 5 : row?.metadata?.numberOfQuestions || 0,
      align: "center",
    },
    {
      id: "attemptedBy",
      label: "Attempted by",
      type: "number",
      renderCell: ({ row }) => row?.attemptedBy?.length || 0,
      align: "center",
    },
    {
      id: "createdAt",
      label: "Created On", type: 'date',
      minWidth: 116,
      align: "center",
      renderCell: ({ row }) => dayjs(row.createdAt).format("DD-MMM-YYYY"),
    },
    {
      id: "link",
      label: "Invite Link", fixed: true,
      minWidth: 120, disableSorting: true,
      align: "center",
      renderCell: ({ row }) =>
        <IconButton size="small" onClick={() => {
          const link = `${process.env.REACT_APP_INPREP_URL}/${getClientPrefix()}/home?aid=${row._id}`;
          copyToClipboard(link);
          snackbar.success('Copied');
        }} >
          <ContentCopyIcon fontSize="small" color="primary" />
        </IconButton>,
    },
    {
      id: "analytics",
      label: "Analytics", fixed: true,
      minWidth: 120, disableSorting: true,
      align: "center",
      renderCell: ({ row }) =>
        <IconButton size="small" onClick={() => navigate(`${row._id}/performance`)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 22 22" fill="none">
            <rect y="14" width="6" height="8" rx="1" fill="#025093" />
            <rect x="8" y="8" width="6" height="14" rx="1" fill="#5856D6" />
            <rect x="16" width="6" height="22" rx="1" fill="#007AFF" />
          </svg>
        </IconButton>,
    },
  ]), []);

  React.useEffect(() => {
    setInitialFilters({ ...initialFilters, archive: activeAssessment === 1 ? true : false })
  }, [activeAssessment])

  React.useEffect(() => {
    applyFiltersByHistory(pageFilters, setPageFilters, setInitialFilters);
  }, [pageHistory])

  React.useEffect(() => {
    getDomains()
      .then(({ interviewDomains }) => {
        setFilterOptions((fO) => ({
          ...fO,
          domain: interviewDomains.map((d) => ({ id: d._id, label: d.name })),
        }));
      })
      .catch(console.error);

    getBatches({ client: Session.userId })
      .then(({ batches }) => {
        setFilterOptions((fO) => ({
          ...fO,
          batch: batches.map((b) => ({ id: b._id, label: b.name })),
        }));
      })
      .catch(console.error);
  }, []);

  const handleSuccess = React.useCallback(() => {
    setDuplicate(false);
    setRefreshData(true);
  }, []);

  const handleDuplicate = React.useCallback((params) => {
    setDuplicate(params.row);
    setRefreshData(false);
  }, []);

  const handleAssessmentChange = React.useCallback((value) => {
    setActiveAssessment(value);
  }, []);

  const createLink = React.useMemo(() => {
    const forBatch = searchParams.get("fb");

    if (forBatch) return "create?batchId=" + forBatch;
    else return "create";
  }, [searchParams]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="calc(100vh - 56px)"
      pb={6}
    >
      <ScreenHeading title="Assessments" id="assessments-tip">
        <Link to={createLink} style={{ textDecoration: "none" }}>
          <CustomButton variant="contained">Create Assessment</CustomButton>
        </Link>
      </ScreenHeading>

      <Box sx={{ width: '100%' }}>
        <Paper elevation={0} style={{  paddingLeft:'20px' , paddingRight:'20px' , marginTop:'16px' }}>
          <AssessmentTabs active={activeAssessment} handleActiveChange={handleAssessmentChange} />

          {
            [0, 1].includes(activeAssessment) ?
              <DynamicTable
                showDummyRows={tourState}
                dummyRows={TourSteps[2].data.templates}
                refreshData={refreshData}
                noRowsMessage="No assessment Found!!"
                hideArchiveAction={false}
                menuOptions={
                  activeAssessment === 0 ? [
                    {
                      id: 'duplicate',
                      label: 'Duplicate Assessment',
                      onClick: handleDuplicate
                    },
                    {
                      id: 'edit',
                      label: 'Edit Questions',
                      onClick: (params) => navigate(`/assessments/${params.id}?section=2`)
                    },
                    {
                      id: 'setting',
                      label: 'Publishing Settings',
                      onClick: (params) => navigate(`/assessments/${params.id}?section=3`)
                    },
                    {
                      label: 'Archive Assessment',
                      id: 'archive',
                    },
                  ] : [
                    {
                      label: 'UnArchive Assessment',
                      id: 'unarchive',
                    },
                  ]
                }
                entity="InterviewTemplate"
                columns={columns}
                filters={filters}
                filterOptions={filterOptions}
                useListProps={{
                  getItems: getTemplates,
                  deleteItemById: deleteTemplateById,
                  archiveItemById: archiveTemplateById,
                  itemName: "interviewTemplates",
                  initialFilters: initialFilters
                }}
                rowSelectionModel={rowSelectionModel}
                setRowSelectionModel={setRowSelectionModel}
              /> :
              <TemplateDrafts />
          }

        </Paper>
      </Box>

      <DuplicateTemplateDialog
        open={!!duplicate}
        onClose={() => setDuplicate(false)}
        template={duplicate}
        onSuccess={handleSuccess}
      />
    </Box>
  );
}

export default Templates;
