import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import CenterFlexBox from "components/CenterFlexBox";
import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";
import ActionButtons from "components/ActionButtons";
import { Session } from "utils";
import { useSnackbar } from "contexts";
import { overwriteClientById } from "services";

const useStyle = makeStyles({
    container: {
        display: 'flex', justifyContent: 'space-between',
    },
    leftContainer: { width: '40%' },
    rightContainer: { width: '40%', },
    title: {
        fontFamily: 'inter', fontWeight: 600, fontSize: 18, marginBottom: 4,
    }, content: {
        fontFamily: 'inter', fontWeight: 500, fontSize: 14,
    }, divider: {
        border: '0.5px solid #D9D9D9',
    }, param: {
        fontFamily: 'inter', fontWeight: 600, fontSize: 15,
    }, percent: {
        fontFamily: 'inter', fontWeight: 600, fontSize: 15,
        border: '1px solid #F1F2F6', padding: 5, borderRadius: 2,
    }
});

export default function Scoring() {
    const classes = useStyle();
    const snackbar = useSnackbar();
    const [weightage, setWeightage] = React.useState({
        communication: Session?.user?.metadata?.performanceWeightage?.communication || 50,
        content: Session?.user?.metadata?.performanceWeightage?.content || 50,
    });

    const [loading, setLoading] = React.useState(false);
    const [edit, setEdit] = React.useState(false);

    const handleChange = (e, v) => {
        setEdit(true);
        setWeightage({ communication: 100 - v, content: v });
    }

    const handleSave = async () => {
        try {
            setLoading(true);

            const client = {
                metadata: { performanceWeightage: weightage }
            };

            const newClient = await overwriteClientById(Session.userId, client);

            Session._setUser(newClient);
            setEdit(false);

            snackbar.showSnackbar('Successfully saved', 'success');
        } catch (error) {
            snackbar.showSnackbar(error.message, 'error');
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.leftContainer}>

                <Slider
                    aria-label="Temperature"
                    defaultValue={Session?.user?.metadata?.performanceWeightage?.content || 50}
                    step={10} min={0} max={100}
                    onChangeCommitted={handleChange}
                />

                <CenterFlexBox justifyContent='space-between'>
                    <Typography className={classes.param}> Speech </Typography>
                    <Typography className={classes.param}> Content </Typography>
                </CenterFlexBox> <br />

                <CenterFlexBox justifyContent='space-between'>
                    <Box className={classes.percent}> {weightage.communication}% </Box>
                    <Box className={classes.percent}> {weightage.content}% </Box>
                </CenterFlexBox> <br /><br />

                <Typography className={classes.content}>
                    <b>Adjust</b> the weightage of <b>Speech</b> and <b>content</b> &nbsp;
                    scores to calculate your overall score as per your preference.
                    This allows you to focus on the aspect that is more relevant to your goals.
                </Typography> <br /><br />

                {(edit) && <ActionButtons
                    loading={loading}
                    primaryFirst
                    primaryAction="Save"
                    secondaryAction="Cancel"
                    primaryActionVariant="primary"
                    secondaryActionVariant="error"
                    onSecondaryClick={() => setEdit(false)}
                    onPrimaryClick={handleSave}
                />}
            </Box>

            <Box className={classes.divider}></Box>

            <Box className={classes.rightContainer} >
                <Typography className={classes.title}>
                    Know how the Scores are calculated :
                </Typography>
                <Typography className={classes.content}>
                    <b>Speech Score:</b> The Speech score is calculated
                    based on four key factors: fluency, vocabulary, word choice, and delivery.
                    Fluency refers to how smoothly you deliver your answer without pauses or hesitations.
                    Vocabulary considers the range and complexity of words you use.
                    Word choice is about selecting the most appropriate words for the given context,
                    while delivery is how well you articulate your thoughts and express yourself. <br />
                    <b>Content Score:</b> The content score is calculated based on three criteria:
                    similarity with the set ideal answer, expected key words for the answer,
                    and relevance of your answer to the domain.
                </Typography>
            </Box>
        </Box>
    );
}