import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ShadowBox from 'components/ShadowBox';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getBatchUserMappings } from 'services';
import { formatDate } from 'utils';


function Enrollments() {
  const { id } = useParams();
  const [enrollments, setEnrollments] = React.useState([]);

  React.useEffect(() => {
    if (id) {
      (async () => {
        const { batchUserMappings } = await getBatchUserMappings({
          user: id, include: 'batch'
        });
        setEnrollments(batchUserMappings.map(bum => ({
          id: bum._id,
          batchName: bum?.batch?.name,
          enrollmentDate: formatDate(bum?.updatedAt, 'Do,MMM YYYY'),
          batchId: bum?.batch?._id,
        })));
      })();
    }
  }, [id]);

  return (
    <Box px={1} pb={1}>
      <Typography variant="subtitle2" mb={1}>Enrollments</Typography>
      <TableContainer
        component={ShadowBox}
        style={{ maxHeight: 136, overflow: "auto", padding: 0, margin: 0 }}
      >
        <Table size="small" stickyHeader >
          <TableHead >
            <TableRow>
              <TableCell >Batch Name</TableCell>
              <TableCell>Enrollment Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {enrollments?.length ? enrollments.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="td" scope="row">
                  {row.batchName}
                </TableCell>
                <TableCell component="td" scope="row">
                  {row.enrollmentDate}
                </TableCell>
              </TableRow>
            )) : (
              <TableRow>
                <TableCell component="td" scope="row">
                  No enrollments found!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Enrollments;