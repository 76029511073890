import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import ScoreCard from "components/ScoreCard";
import { getScoresByUserId, getUserAttemptRate } from "services";
import { getAverageScore } from "utils";

function Scores({ student }) {
  const [scores, setScores] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (student._id) {
        try {
          const {
            communicationOverall,
            contentOverall,
            highestAverage,
            lowestAverage
          } = await getScoresByUserId("inprep", student._id);
          const { totalTemplates, templateAttempted } =
            await getUserAttemptRate("inprep", student._id);

          setScores([
            {
              isPercentage: true,
              label: "Attempt Rate",
              color: "#0263FF",
              score:
                totalTemplates > 0
                  ? Math.round((templateAttempted / totalTemplates) * 100)
                  : 0,
            },
            {
              label: "Average Score",
              score: getAverageScore(communicationOverall, contentOverall) || 0,
              color: "#8E30FF",
            },
            {
              label: "Highest Score",
              color: "#00C7BE",
              score: Math.round(highestAverage) || 0,
            },
            {
              label: "Lowest Score",
              color: "#FF2D55",
              score: Math.round(lowestAverage) || 0,
            },
          ]);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [student]);

  return (
    <>
      <Typography variant="h1" fontWeight={600} fontSize="28px" my={2}>
        Overall Performance
      </Typography>
      <Box
        display="flex"
        alignItems="stretch"
        gap="30px"
        mb="2em"
      >
        {scores.map((score, i) => (
          <ScoreCard
            key={i}
            {...score}
            style={{
              maxWidth: "18%",
              backgroundColor: "white",
              paddingTop: 16,
            }}
          />
        ))}
      </Box>
    </>
  );
}

export default Scores;
