import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

import ArrowBack from '@mui/icons-material/ArrowBack';
import CustomButton from "components/CustomButton";
import Select from "components/Select";
import DetailsForm from "./DetailsForm";
import FieldWrapper from "editors/TemplateEditor/FieldWrapper";
import { getDomains, getPersonalizedInterview } from "services";
import TextField from "components/TextField";
import { getCompanies } from 'services/tagService';
import { useServices } from 'contexts/SingleSessionProvider';
import { copyToClipboard, Session } from "utils";
import { Grow, IconButton } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from "contexts";

const predefinedCompanyState = {
    company: "",
    jobRole: "",
    companyId: "",
    jobId: "",
    domain: "",
    jobDescription: ""
}

const userdefinedCompanystate = {
    company: "",
    jobRole: "",
    domain: "",
    domainId: "",
    jobDescription: ""
}

const useStyle = makeStyles(theme => ({
    container: {
        borderRadius: theme.spacing(0.5),
        overflow: "hidden",
        background: "linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)",
        boxShadow: " 0px 0px 4px 0px #00000026",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(7.5, 0)
    },
    heading: {
        fontSize: "28px",
        fontWeight: 500,
        lineHeight: "35.84px",
        textAlign: "left"
    },
    box: {
        padding: theme.spacing(5, 7.5),
        display: "flex",
        gap: theme.spacing(2.5),
        flexDirection: "column",
        width: "700px",
        background: "#FFFFFF",
        borderRadius: theme.spacing(0.5),
        height:'376px',
    },
    detailFormBox: {
        padding: theme.spacing(5, 7.5),
        display: "flex",
        gap: theme.spacing(2.5),
        flexDirection: "column",
        width: "700px",
        background: "#FFFFFF",
        borderRadius: theme.spacing(0.5),
    },
    boxMock:{
        padding: theme.spacing(5, 7.5),
        display: "flex",
        gap: theme.spacing(2.5),
        flexDirection: "column",
        width: "940px",
        background: "#FFFFFF",
        borderRadius: theme.spacing(0.5),
    },
    h3: {
        fontSize: "19px",
        fontWeight: 500,
        lineHeight: "23.56px",
        textAlign: "left"
    },
    borderBottom: {
        borderBottom: "1px solid #8692A3",
        paddingBottom: theme.spacing(1.5)
    },
    body: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "20.48px",
        textAlign: "left"
    },
    question: {
        display: "flex",
        gap: theme.spacing(2.5)
    },
    dropdown: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(1)
    },
    link: {
        fontSize: " 16px",
        fontWeight: 600,
        lineHeight: "20.48px",
        textDecoration: "underline",
        color: "#1961BF",
        cursor: "pointer",
        textAlign: "center"
    },
    backBox: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
    },
    hoverButton: {
        '&:hover': {
            cursor: "pointer",
            boxShadow: "0px 0px 4px 0px #00000026",
            borderRadius: 100
        },
    },
    none: {
        display: "none"
    },
    newCompanyBox: {
        display: "flex",
        gap: theme.spacing(2.5)
    },
    newComapnyContainer: {
        display: "flex",
        gap: theme.spacing(2.5),
        flexDirection: "column",
    },
    headBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    creditsTag:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        padding:theme.spacing(0.5,1),
        borderRadius:'4px'
    },
    creditText:{
        fontSize:16,
        fontWeight:600,
        textDecoration:'underline'
    },
    creditBox:{
        display: "flex",
        flexDirection: "column",
        width: "700px",
        background: "#FFFFFF",
        borderRadius: theme.spacing(0.5),
    },
    creditUpperBox:{
        padding:theme.spacing(3),
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        gap:theme.spacing(2),
        backgroundColor:'#FFE6E3',
        borderRadius: theme.spacing(0.5),
    },
    gifText:{
        fontSize:28,
        fontWeight:500,
        color:'#C51407'
    },
    contentBox:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        padding:theme.spacing(2.5,5),
        gap:theme.spacing(2.5)
    },
    totalAttemptText:{
        fontSize:16,
        fontWeight:500,
        textAlign:'center'
    },
    getInTouch : {
        display:'flex',
        flexDirection:'column',
        paddingTop:theme.spacing(1.5),
        borderTop: '1px solid #CCD4DE',
        width:'580px',
        alignItems:'center',
        gap:theme.spacing(1)
    },
    touchTextBox:{
        fontSize:19,
        fontWeight:700
    },
    copyClipboardBox:{
        display:'flex',
        gap:theme.spacing(1)
    },
    emailClip:{
        padding:theme.spacing(0.5,1.5),
        display:'flex',
        gap:theme.spacing(0.5),
        border:'1px solid #CCD4DE',
        borderRadius:'4px'
    },
    spantext:{
        color:'#009970',
        fontWeight:600,
    }
}))

const ZeroCreditModal = ({ totalAttempts }) => {
    const classes = useStyle();
    const snackbar = useSnackbar();

    return (
        <Grow in={true} timeout={600}> 
            <Box className={classes.creditBox}>
                <Box className={classes.creditUpperBox}>
                    <img width={80} height={80} src="https://languify-assets.s3.ap-south-1.amazonaws.com/gifs/Face-in-clouds.gif" alt="cloud" />
                    <Typography className={classes.gifText}>Your free trial credits are exhausted!</Typography>
                </Box>
                <Box className={classes.contentBox}>
                    <Box>
                        <Typography className={classes.totalAttemptText}>
                            <span className={classes.spantext}>Turn Interview Anxiety into Interview Success!</span>
                        </Typography>
                        <Typography className={classes.totalAttemptText}>
                            Enable your students to walk into any interview with confidence. Secure their preparation; purchase today
                        </Typography>
                    </Box>
                    <Box className={classes.getInTouch}>
                        <Typography className={classes.touchTextBox}>Get in touch with us for the credits</Typography>
                        <Box className={classes.copyClipboardBox}>
                            <Box className={classes.emailClip}>
                                <Typography sx={{ color: '#003E8C', fontWeight: 600, fontSize: '16px' }}>growth@languify.in</Typography>
                                <IconButton sx={{ padding: 0, color: '#003E8C' }} onClick={() => {
                                    copyToClipboard('growth@languify.in');
                                    snackbar.success('Copied!');
                                }}>
                                    <ContentCopyIcon fontSize='small' />
                                </IconButton>
                            </Box>
                            <Box className={classes.emailClip}>
                                <Typography sx={{ color: '#003E8C', fontWeight: 600, fontSize: '16px' }}>9969854525</Typography>
                                <IconButton sx={{ padding: 0, color: '#003E8C' }} onClick={() => {
                                    copyToClipboard('9969854525');
                                    snackbar.success('Copied!');
                                }}>
                                    <ContentCopyIcon fontSize='small' />
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Grow>
    );
}

function NewCompnayData({ newCompanyData, setNewCompanyData, ...props }) {
    const classes = useStyle();
    const [domain, setDomains] = React.useState([])

    React.useEffect(() => {
        (async () => {
            try {
                const { interviewDomains } = await getDomains();
                setDomains(interviewDomains.sort((a, b) => a.name.localeCompare(b.name)));
            } catch (error) { 
                console.error(error);
            }
        })();
    }, []);

    const handleDescChange = (e) => {
        setNewCompanyData({ ...newCompanyData, jobDescription: e.target.value })
    }

    const handleRoleChange = (e) => {
        setNewCompanyData({ ...newCompanyData, jobRole: e.target.value })
    }

    const handleNameChange = (e) => {
        setNewCompanyData({ ...newCompanyData, company: e.target.value })
    }

    const handleDomainChange = (e) => {
        
        const selectedDomain = domain.find(tmp => tmp._id === e.target.value);
        setNewCompanyData(prevState => ({
            ...prevState,
            domainId: e.target.value,
            domain: selectedDomain?._id
        }));
    };

    return (
        <Box className={classes.newCompanyContainer} {...props}>
            <Box className={classes.newCompanyBox}>
                <FieldWrapper
                    title="Enter company name"
                    description=""
                    className={classes.padding}
                    flexGrow={1}
                    px={0}
                    pb={0}
                >
                    <TextField
                        name="name"
                        placeholder="Company name"
                        value={newCompanyData.company}
                        onChange={handleNameChange}
                        background='#FFFFFF'
                        border='1px solid #636F7F'
                    />
                </FieldWrapper>
                <FieldWrapper
                    title="Enter Job role"
                    description=""
                    className={classes.padding}
                    flexGrow={1}
                    px={0}
                    pb={0}
                >
                    <TextField
                        name="role"
                        placeholder="Job role "
                        value={newCompanyData.job}
                        onChange={handleRoleChange}
                        background='#FFFFFF'
                        border='1px solid #636F7F'
                    />
                </FieldWrapper>
                <FieldWrapper
                    title="Select domain"
                    px={0}
                    pb={0}
                    flexGrow={1}
                >
                    <Select
                        options={domain}
                        value={newCompanyData.domainId}
                        onChange={handleDomainChange}
                        defaultValue={newCompanyData.domainId}
                        size="100%"
                        background='#FFFFFF'
                        boxShadow='none'
                        sx={{minWidth:'210px' , margin:'4px' , height:'39px'}}
                    />
                </FieldWrapper>
            </Box>
            <FieldWrapper
                title="Job description"
                description=""
                className={classes.padding}
                width="unset"
                px={0}
                pb={0}
                paddingBottom={1}
            >
                <textarea
                    className="question-input"
                    name="jobDescription"
                    value={newCompanyData.jobDescription}
                    onChange={handleDescChange}
                    placeholder="Paste company job description here..."
                    rows={6}
                    style={{ margin: 0 , backgroundColor:'#FFFFFF' , border:'1px solid #636F7F' }}
                />
            </FieldWrapper>
        </Box>
    );
}

export default function CreateInterview({refresh, setRefresh}) {
    const classes = useStyle();
    const service = useServices();

    const personalizedInterviewLimit = 
        Session?.user?.metadata?.personalizedInterviewLimit?.client 
        || process.env.REACT_APP_PERSONALIZED_INTERVIEW_LIMIT;    
    
    const [companies, setCompanies] = useState();
    const [predefinedCompanyData, setPredefinedCompanyData] = useState({ ...predefinedCompanyState })
    const [userdefinedCompanyData, setUserDefinedCompanyData] = useState({ ...userdefinedCompanystate })
    const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [newCompany, setNewCompany] = useState(false);
    const [nextStep, setnextStep] = useState(0);
    const [sendValue, setSendValue] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openCreditModal , setOpenCreditModal] = useState(false)
    const [credits, setCredits] = useState(process.env.REACT_APP_PERSONALIZED_INTERVIEW_LIMIT);
    const [totalTemplates , setTotaltemplates] = useState(0)
    const [totalAttempts , setTotalAttempts] = useState(0)

    const [initialFilters, setInitialFilters] = React.useState({
        user: Session.userId,
        includeTotal: true,
        type: "personalized",
        orderBy: "updatedAt",
        order: "desc",
      });

      React.useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const {total , templates } = 
                  await service.callService(getPersonalizedInterview,initialFilters);
                              
                setTotaltemplates(total);
                const totalAttemptsSum = templates.reduce(
                    (acc, template) => acc + (template.totalAttemptsCount || 0)
                  , 0);

                setTotalAttempts(totalAttemptsSum);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchTemplates()
    },[refresh])

      React.useEffect(() => {
        const personalizedInterviewLimit = parseInt(Session?.user?.metadata?.personalizedInterviewLimit?.client  
                                                    || process.env.REACT_APP_PERSONALIZED_INTERVIEW_LIMIT)
    
        if(personalizedInterviewLimit){
          setCredits(personalizedInterviewLimit - totalTemplates);
        }
      
      }, [totalTemplates, Session?.user]);
  
      React.useEffect(() => {
        if (credits <= 0) {
            setTimeout(() => setOpenCreditModal(true), 600);
        }
        }, [credits]);

    React.useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const fetchedCompanies = await service.callService(getCompanies);
                setCompanies(Object.values(fetchedCompanies).sort((a, b) => a.name.localeCompare(b.name)))
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchCompanies();
    }, []);

      React.useEffect(() => {
        const isDisabled = newCompany
            ? !(userdefinedCompanyData.company && userdefinedCompanyData.jobRole &&
                userdefinedCompanyData.domain && userdefinedCompanyData.jobDescription)
            : !(predefinedCompanyData.jobRole && predefinedCompanyData.company);
    
            const isCreditInvalid = credits <= 0 
        
        setIsButtonDisabled(isDisabled || isCreditInvalid);
    }, [newCompany, predefinedCompanyData, userdefinedCompanyData, credits]);
    

    const handleCompanyChange = (e) => {
        const selectedIndex = companies.findIndex(company => company._id === e.target.value);
        setSelectedCompanyIndex(selectedIndex);
    
        setPredefinedCompanyData({
            ...predefinedCompanyData,
            company: companies[selectedIndex]?.name,
            companyId: e.target.value
        });
    };

    const handleJobChange = (e) => {
        const selectedJob = companies[selectedCompanyIndex].jobRoles
            .find(job => job._id === e.target.value);
        
        setPredefinedCompanyData(prevState => ({
            ...prevState,
            jobId: e.target.value,
            jobRole: selectedJob?.name,
            domain: selectedJob?.domain?._id,
            jobDescription: selectedJob?.jobDescriptions?.content
        }));
    };

    const handleNextStep = () => {
        setSendValue(newCompany ? userdefinedCompanyData : predefinedCompanyData);
        setnextStep(nextStep + 1);
    };

    const handleSetSendValue = () => {
        setPredefinedCompanyData({ ...predefinedCompanyState })
        setUserDefinedCompanyData({ ...userdefinedCompanystate })
        setNewCompany(false)
        setSelectedCompanyIndex(null)
    }

    const handleCompany = () => { setNewCompany(!newCompany) }

    const handleback = () => { setnextStep(nextStep - 1) }

    return (
        <Box className={classes.container}>
          {openCreditModal 
          ? (
           <ZeroCreditModal totalAttempts={totalAttempts}/>
          ) 
          : (
            <>
              <Box className={newCompany ? classes.boxMock : nextStep !== 1 ? classes.box : classes.detailFormBox}>
               <Box className={classes.headBox}>
                 <Typography className={classes.heading}>
                    Create mock interview
                 </Typography>
                {nextStep !== 1 && totalTemplates ? (
                     <Box className={classes.creditsTag} sx={{backgroundColor: credits <= 0 ? '#FFE6E3' : '#F0F5FA'}}>
                        <Typography className={classes.creditText} sx={{color: credits <= 0 ? '#C51407' : '#1961BF'}}>
                            Free trial credits: {credits <= 0 ? 0 : credits}
                        </Typography>
                    </Box>
                ) : <></>}
               </Box>
                <Box className={`${classes.borderBottom} ${classes.backBox}`} >
                    <ArrowBack
                        onClick={handleback}
                        className={`${classes.hoverButton} ${!nextStep ? classes.none : ''}`}
                        sx={{ color: "#1961BF" }}
                    />
                    <Typography className={classes.h3}>
                        {nextStep ? "Publishing details & proctorship" : "Job details"}
                    </Typography>
                </Box>
                {!nextStep ? (
                    !newCompany ? (
                        <Box className={classes.question}>
                            <Box className={classes.dropdown}>
                                <Typography className={classes.body}>
                                    Select company
                                </Typography>
                                <Select
                                    options={companies || []}
                                    value={predefinedCompanyData.companyId}
                                    onChange={handleCompanyChange}
                                    background='#FFFFFF'
                                    boxShadow='none'
                                />
                            </Box>
                            <Box className={classes.dropdown}>
                                <Typography className={classes.body}>
                                    Select job role
                                </Typography>
                                <Select
                                    options={
                                             selectedCompanyIndex !== null 
                                             ? companies[selectedCompanyIndex].jobRoles.sort((a, b) => a.name.localeCompare(b.name)) 
                                             : []
                                            }
                                    value={predefinedCompanyData.jobId}
                                    onChange={handleJobChange}
                                    background='#FFFFFF'
                                    boxShadow='none'
                                />
                            </Box>
                        </Box>
                    ) : (
                        <NewCompnayData
                            newCompanyData={userdefinedCompanyData}
                            setNewCompanyData={setUserDefinedCompanyData}
                        />
                    )
                ) : (
                    <DetailsForm setSendValue={handleSetSendValue}
                        setnextStep={setnextStep} sendValue={sendValue} setRefresh={setRefresh}
                        nextStep={nextStep}/>
                )}

                {!nextStep && (
                    <>
                        <CustomButton
                            disabled={isButtonDisabled}
                            variant="contained"
                            onClick={handleNextStep}
                        >
                            Next
                        </CustomButton>
                        <Typography className={classes.link} onClick={handleCompany}>
                            {newCompany ?
                                "Want to select from existing company & job role? Click here"
                                :
                                "Didn’t find the company or job role you are looking for? Click here"
                            }
                        </Typography>
                    </>
                )}
            </Box>
            </>
          )}
        </Box>
    )
}


