import Client from "./_client";
import * as Papa from "papaparse";
import { Session } from "utils";
import dayjs from "dayjs";

export const getScoresByUserId = async (application, userId) => {

  let url = `/analytics/${application}/${userId}/scores`;

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message || "Uh Oh! Unable to get scores. Please try again"
    );

  return result.data || {};
};

export const getUserAttemptRate = async (application, userId) => {

  let url = `/analytics/${application}/${Session.userId}/${userId}/attempt-rate`;

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message || "Uh Oh! Unable to get attempt rate. Please try again"
    );

  return result.data || {};
};

export const getInterviewTemplateQuestionScores = async (templateId) => {

  let url = `/analytics/inprep/${templateId}/question-scores`;

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message ||
      "Uh Oh! Unable to get attempt question scores. Please try again!"
    );

  return result.data || {};
};

export const getInterviewTemplateOverallAnalytics = async (batchId) => {

  let url = `/analytics/template/overall`;
  let query = `?batchId=${batchId}`;
  if (batchId) url += query;

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message ||
      "Uh Oh! Unable to get template analytics. Please try again!"
    );

  return result.data || {};
};

export const getUsersAverageScoreWithRange = async () => {

  let url = `/analytics/users/average-scores`;

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200))
    throw new Error(
      result.message ||
      "Uh Oh! Unable to get template analytics by batch. Please try again!"
    );

  return result.data || {};
};

export const exportTemplateAnalytics = async (template) => {
  let url = `/analytics/template-analytics?templateId=${template._id}`;

  url += `&clientId=${Session.userId}`

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to export performance");
  }

  const { columns, headers, rows } = result?.data;
  return { columns, headers, rows } || {};
}

export const exportTemplateAnalyticsExport = async (template) => {
  let url = `/analytics/v2/template-analytics?templateId=${template._id}`;

  url += `&clientId=${Session.userId}`

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to export performance");
  }

  const { columns, headers, rows } = result?.data;
  return { columns, headers, rows } || {};
}

export const getQuickHiglights = async (batchId) => {

  let url = `analytics/highlights/${batchId}`;

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to get highlights");
  }
  return result?.data;

}