import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import DebouncedTextField from 'components/DebouncedTextField';
import CustomButton from 'components/CustomButton';
import { getTemplateTags } from 'services';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogInfo = {
    keywords: { key: "KEYWORD", title: "Add Keywords", label: "keywords" },
    subTopics: { key: "SUB_TOPIC", title: "Add Topics", label: "topics" },
};

function TemplateTagDialog({
    type, open, onClose, domain = "", tags = []
}) {
    const [error, setError] = React.useState("");
    const [values, setValues] = React.useState(tags);
    const [suggestions, setSuggestions] = React.useState(["new"]);

    const { title, label, key } = DialogInfo?.[type] || {};

    const handleChange = async (value) => {
        const _value = value?.trim();
        if (_value) {
            let { templateTags } = await getTemplateTags({
                domain,
                type: key,
                keyword: _value,
            });

            templateTags = templateTags.map(tt => tt.keyword);

            setSuggestions(Array.from(new Set(templateTags)));
        } else {
            setSuggestions(["new"]);
        }
    };

    React.useEffect(() => {
        setError("");
        setValues(tags);
    }, [open]);

    const addTag = (tag) => {
        if (values.includes(tag)) return;

        const newTags = [...values, tag];

        setValues(newTags);
    };

    const deleteTag = (tag) => setValues(values.filter(t => t !== tag));

    const handleSubmit = (e) => {
        e.preventDefault();

        let _error;
        if (tags.length >= 10) _error = `Max 10 ${label} allowed`;

        const formData = new FormData(e.target);

        const _value = formData.get(type)?.trim();

        if (_value.length >= 100) _error = "Max 100 chars allowed";

        if (_error) setError(_error);
        else {
            addTag(_value);

            e.target.reset();

            setError("")
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => onClose(values)}
            fullWidth
            TransitionComponent={Transition}
        >
            <IconButton
                aria-label="close"
                onClick={() => onClose(values)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent >
                <Typography>
                    Add {label} for the assessment
                </Typography>
                <form onSubmit={handleSubmit} style={{ display: "flex", alignItems: "flex-start" }} >
                    <DebouncedTextField
                        required
                        type="alphanum"
                        max={20}
                        InputProps={{ max: 20 }}
                        name={type}
                        onChange={handleChange}
                        placeholder="Type here..."
                        error={!!error}
                        helperText={!!error && error}
                    />
                    &nbsp;&nbsp;
                    <CustomButton type="submit" sx={{ mt: 1 }}>Add</CustomButton>
                </form>
                <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
                    {values.map((tag, index) => (
                        <Chip
                            key={index}
                            label={tag}
                            variant="contained"
                            color="primary"
                            onDelete={() => deleteTag(tag)}
                            style={{ marginRight: 4, marginBottom: 4 }}
                        />
                    ))}
                </Stack>
                <br />
                <Box
                    border="1px solid #02569D"
                    p={2}
                    borderRadius={1}
                >
                    <Typography mb={1} variant="body1">Suggestions based on your selections</Typography>
                    <Stack direction="row" flexWrap="wrap" justifyContent="flex-start">
                        {suggestions.map((suggestion, index) => (
                            <Chip
                                clickable
                                key={index}
                                label={suggestion}
                                variant="outlined"
                                color="primary"
                                style={{ marginRight: 4, marginBottom: 4 }}
                                onClick={() => addTag(suggestion)}
                            />
                        ))}
                    </Stack>
                </Box>

            </DialogContent>
        </Dialog>
    );
}

export default TemplateTagDialog;
