import React, { useEffect, useRef } from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import * as Yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

import CustomButton from "components/CustomButton";
import TextField from "components/TextField";
import FieldWrapper from "editors/TemplateEditor/FieldWrapper";
import DatePickerField from "editors/TemplateEditor/DatePickerField";
import { useLoading, useSnackbar } from "contexts";
import useEditorStore from "editors/TemplateEditor/editorStore";
import ProctorSettings from "editors/TemplateEditor/ProctorSettings";
import BatchSelection from "editors/TemplateEditor/BatchSelection";
import { getDraftForUser, getDrafts, getPersonalizedInterview, getTemplates, saveDraft } from 'services';
import theme from "theme";
import { Session } from "utils";
import { useServices } from "contexts/SingleSessionProvider";

dayjs.extend(advancedFormat);

const ValidationSchema = Yup.object({
  name: Yup.string().trim().required("Give a name to your template"),
  batch: Yup.string().required("Select a batch"),
  publishOn: Yup.date().required(),
  expiresOn: Yup.date().required(),
  proctorSettings: Yup.object({
    copyPasteProctoring: Yup.bool().optional().default(true),
    generalProctoring: Yup.bool().optional().default(false),
    imageCaptureProctoring: Yup.bool().optional().default(false),
  }).optional(),
});

const DefaultValues = {
  name: "",
  batch: "",
  publishOn: "",
  expiresOn: "",
  proctorSettings: {
    copyPasteProctoring: true,
    generalProctoring: true,
    imageCaptureProctoring: false,
  },
};

const useStyles = makeStyles((theme) => ({
  borderBottom: {
    borderBottom: "1px solid #CCD4DE",
  },
  padding: {
    padding: 0
  },
  box: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5)
  }
}))

const ActionStyle = {
  width: "100%",
  margin: 0,
};


function DetailsForm({ sendValue,setSendValue,setRefresh, ...props }) {
  const classes = useStyles();
  const loading = useLoading();
  const snackbar = useSnackbar();
  const service = useServices();
  const hasSetNameRef = useRef(false);

  const draftId = useEditorStore((state) => state.draftId);
  const reset = useEditorStore((state) => state.reset);

  const fields = new Set([
    "_id",
    "name",
    "expiresOn",
    "publishOn",
    "batch",
    "proctorSettings",
    "company",
    "jobRole",
    "jobDescription",
    "domain",
    "type"
  ]);

  const {
    handleSubmit,
    values,
    handleChange,
    errors,
    setFieldValue,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: DefaultValues,
    validationSchema: ValidationSchema,
    onSubmit: async () => {
      try {
        loading.show();
        const filteredValues = Object.keys(values).reduce((acc, key) => {
          if (fields.has(key) && values[key] !== undefined) {
              acc[key] = values[key];
          }
          return acc;
        }, {});

        Object.keys(sendValue).forEach((key) => {
          if (fields.has(key) && sendValue[key] !== undefined) {
              filteredValues[key] = sendValue[key];
          }
        });
        
        await saveDraft(draftId,{name:"INTERVIEW_DETAILS",data: {...filteredValues,type:"personalized"}});
        setSendValue();
        reset();
        props.setnextStep(0);
        setRefresh(true)
        snackbar.showSnackbar("Published Successfully");
      } catch (error) {
        snackbar.showSnackbar(
          error.message || "Uh Oh! Unable to save your changes",
          "error"
        );
      } finally {
        loading.hide();
      }
    },
  });

  const handleDateChange = React.useCallback(
    (name, date) => setFieldValue(name, date),
    [setFieldValue]
  );

  const isPublishDisabled = !values.name || !values.batch;

  useEffect(() => {
    (async () => {
      if (!hasSetNameRef.current) {
        try {
          let name = `${sendValue?.company}: ${sendValue?.jobRole}`;
          
          const filter = { name: name, isActive: true };
          const draftFilters = { user: Session.userId, type: "personalized" };
          const { interviewTemplates } = await service.callService(getTemplates, filter);
          const { drafts } = await service.callService(getDrafts, draftFilters);
          
          // Filter drafts where drafts.user.client is equal to Session.userId
          const filteredDrafts = drafts.filter(draft => draft.user?.client === Session.userId);
  
          let newInterviewName = name;
  
          if (interviewTemplates.length > 0) {
            const suffix = (interviewTemplates.length).toString().padStart(2, '0');
            newInterviewName = `${name}-${suffix}`;
          }
  
          const checkNameConflict = (nameToCheck) => {
            let increment = 1;
  
            const hasConflict = (nameToCheck) => {
              const matchingTemplate = interviewTemplates.find(template => template.name === nameToCheck);
              const matchingDraft = filteredDrafts.find(draft => draft.data.INTERVIEW_DETAILS?.name === nameToCheck);
              return matchingTemplate || matchingDraft;
            };
  
            let nameWithIncrement = nameToCheck;
            while (hasConflict(nameWithIncrement)) {
              const suffix = increment.toString().padStart(2, '0'); 
              nameWithIncrement = `${nameToCheck}-${suffix}`;
              increment++;
            }
  
            return nameWithIncrement;
          };
  
          newInterviewName = checkNameConflict(newInterviewName);
  
          setFieldValue('name', newInterviewName);
  
          hasSetNameRef.current = true;
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, []);  

  return (
    <Box {...props}>
      <form className={classes.box}>
        <FieldWrapper
          title="Enter mock interview name"
          description=""
          width="unset"
          className={classes.padding}
          px={theme.spacing(0)}
          pb={theme.spacing(0)}
        >
          <TextField
            name="name"
            placeholder="Select"
            value={values?.name}
            onBlur={handleBlur}
            onChange={handleChange}
            error={touched.name && errors.name}
            helperText={touched.name && errors.name}
          />
        </FieldWrapper>
        <Box className={classes.borderBottom}></Box>
        <BatchSelection
          onChange={handleChange}
          value={values?.batch || ""}
          error={touched.batch && errors.batch}
          helperText={touched.batch && errors.batch}
          width="unset"
        />
        <Box className={classes.borderBottom}></Box>
        <DatePickerField
          name="publishOn"
          label="Publish mock interview"
          value={values.publishOn}
          onChange={handleDateChange}
          defaultValue={dayjs().toISOString()}
          width="unset"
        />
        <DatePickerField
          name="expiresOn"
          label="Expiry mock interview"
          value={values.expiresOn}
          onChange={handleDateChange}
          disabled={!!!values.publishOn}
          minDate={values?.publishOn ? values.publishOn : undefined}
          defaultValue={dayjs().set("year", 9999).endOf("year").toISOString()}
          width="unset"
        />
        <Box className={classes.borderBottom}></Box>
        <ProctorSettings
          values={values?.proctorSettings || {}}
          onChange={handleChange}
          boxShadow='none'
        />
        <CustomButton
          variant="contained"
          onClick={handleSubmit}
          style={ActionStyle}
          disabled={isPublishDisabled}
        >
          Publish Now
        </CustomButton>
      </form>
    </Box>
  );
}

export default DetailsForm;
