import React from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import DialogTitle from "components/DialogTitle";
import CustomButton from 'components/CustomButton';
import OptionChips from 'editors/TemplateEditor/OptionChips';
import { useSnackbar } from 'contexts';

function DatePickerField({ name, defaultValue, label, onChange, minDate }) {
    const [options, setOptions] = React.useState([]);
    const [date, setDate] = React.useState(defaultValue);
    const [openDatePicker, setOpenDatePicker] = React.useState(false);

    React.useEffect(() => {
        if (defaultValue) setDate(defaultValue);

        let _options = [];

        if (name === "publishOn") {
            _options.push({ label: "Now", value: dayjs().toISOString() });
            _options.push({
                label: "After 24 hrs",
                value: dayjs().add(24, 'hour').toISOString()
            });
        } else {
            _options.push({
                label: "Never",
                value: dayjs().set('year', 9999).endOf('year').toISOString(),
            });
            _options.push({
                label: "After 1 week",
                value: dayjs().add(1, 'week').endOf('day').toISOString()
            });
        }
        setOptions(_options);
    }, [name, defaultValue]);

    React.useEffect(() => onChange(name, date), [date, name]);

    const handleOptionChange = (e) => setDate(e.target.value);

    return (<>
        <Typography>{label}</Typography>
        <Box display="flex" alignItems="center" width="100%">
            <OptionChips
                style={{ width: "fit-content" }}
                name="publishOn"
                value={date}
                onChange={handleOptionChange}
                options={options}
            />
            <CustomButton
                style={{ borderRadius: 500 }}
                size="large"
                startIcon={<CalendarIcon />}
                variant={date ? "contained" : "outlined"}
                onClick={() => setOpenDatePicker(true)}
            >
                {date ? (
                    dayjs(date).format("DD MMM YYYY h:mm A")
                ) : (
                    "Pick date and time"
                )}
            </CustomButton>
            <div
                style={{
                    visibility: 'hidden', height: 0, overflow: "hidden", width: 0
                }}
            >
                <MobileDateTimePicker
                    open={openDatePicker}
                    value={date}
                    onClose={() => setOpenDatePicker(false)}
                    onChange={setDate}
                    renderInput={(params) => <TextField {...params} />}
                    disablePast
                    minDate={minDate}
                />
            </div>
        </Box>
    </>);
}

function TemplateScheduleDialog({
    open = true,
    expiresOn,
    publishOn,
    onClose = () => { },
    onSchedule = () => { },
}) {
    const snackbar = useSnackbar();
    const [dates, setDates] = React.useState({ expiresOn, publishOn });

    const handleOptionChange = (name, date) => {
        setDates({ ...dates, [name]: date });
    };

    const handleSave = () => {
        if (dayjs(dates.expiresOn).isAfter(dates.publishOn)) onSchedule(dates);
        else snackbar.error("Expiry time should be greater than publish time");
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle onClose={onClose}>
                Schedule Assessment
            </DialogTitle>
            <DialogContent style={{ overflowX: "hidden" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePickerField
                        name="publishOn"
                        defaultValue={publishOn}
                        label="Publish On"
                        onChange={handleOptionChange}
                    />
                    <DatePickerField
                        name="expiresOn"
                        defaultValue={expiresOn}
                        label="Expires On"
                        onChange={handleOptionChange}
                        minDate={dates?.publishOn ? dates.publishOn : undefined}
                    />
                </LocalizationProvider>
                <Box display="flex" justifyContent="flex-end" >
                    <CustomButton
                        onClick={handleSave}
                        variant="contained"
                    >
                        Schedule
                    </CustomButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
}

export default TemplateScheduleDialog;
